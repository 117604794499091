import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { PencilLineIcon } from 'lucide-react';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { sendCorrection } from '@/api/client';
import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import Button from '@/components/atoms/Button/Button/Button';
import useBrains from '@/hooks/useBrains';
import useIntents from '@/hooks/useIntents';
import { Intent } from '@/models/intent';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import { AmendPayload } from '@/models/tryIt';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { muiStyles } from '@/modules/TryIt/utils/helper';
import { getPermissions } from '@/redux/permissions/selectors';
import { trackEvent } from '@/segment/segment';
import { intentSchema } from '@/util/validator';

import styles from '../AmendWrapper/AmendWrapper.module.scss';

interface Form {
  intent: {
    label: string;
    value: string;
  };
}

interface Props {
  userMessage: string;
  possibleMatchedIntents: {
    intent: string;
    confidence: number;
  }[];
  brainId: string;
  setAmended: (value: boolean) => void;
  amendPayload: AmendPayload;
  amended: boolean;
}
export const AmendIntent = ({
  possibleMatchedIntents,
  brainId,
  userMessage,
  amendPayload,
  setAmended,
  amended,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isAmending, setIsAmending] = useState(false);
  const { brain } = useBrains(brainId);
  const { brain: draftBrain } = useBrains(brain?.brain_parent_id);

  const {
    intents: hookIntents,
    createNewIntent,
    updateIntentAsync,
    updateStatus,
  } = useIntents(draftBrain?.brain_id);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const [show, setShow] = useState(false);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      intent: null,
    },
    resolver: yupResolver(intentSchema) as Resolver<Form>,
  });

  const selectedIntent = watch('intent');

  const existingIntent = hookIntents?.find(
    (i) => i.intent === selectedIntent?.value
  );
  const trainingPhraseExists = !!existingIntent?.expressions?.find(
    (phrase) => phrase === userMessage
  );

  const onSubmit = async (data: Form) => {
    const intentName = data.intent.value;
    // We use hookIntents because its entries have the expressions property
    if (existingIntent) {
      const uniquePhrases = [userMessage, ...existingIntent.expressions];
      try {
        await updateIntentAsync({
          name: existingIntent.intent,
          intent: {
            expressions: uniquePhrases,
          },
        });
        const intentBelongsToPossibleMatchedIntents =
          possibleMatchedIntents?.some(
            (intent) => intent.intent === intentName
          );
        if (intentBelongsToPossibleMatchedIntents) {
          setIsAmending(true);
          const payload = {
            ...amendPayload,
            task: 'planner' as const,
            correction: intentName,
          };

          await sendCorrection(payload);
          setIsAmending(false);
          setAmended(true);
        }
        setShow(false);
      } catch (e) {
        dispatch(addErrorTemporalToast(e));
        setIsAmending(false);
      }
    } else {
      const newIntent: Partial<Intent> = {
        intent: intentName,
        collection: '',
        expressions: [userMessage],
      };
      const result = await createNewIntent(newIntent);
      setShow(false);
      dispatch(
        addTemporalToast(
          'success',
          t('intent.intent_created', { 0: result?.intent })
        )
      );
    }
    setAmended(true);
  };

  const options =
    possibleMatchedIntents
      ?.filter((intent) => intent.intent !== 'offtopics')
      ?.map((intent) => ({
        label: `#${intent.intent}`,
        value: `${intent.intent}`,
        type: t('try_it.considered_intents'),
      })) ?? [];

  const otherIntents =
    hookIntents
      ?.filter(
        (intent) =>
          !possibleMatchedIntents?.some((p) => p.intent === intent.intent) &&
          intent.intent !== 'offtopics'
      )
      ?.map((intent) => ({
        label: `#${intent.intent}`,
        value: `${intent.intent}`,
        type: t('try_it.other_intents'),
      })) ?? [];

  if (amended) {
    return (
      <Box
        display="flex"
        gap="var(--space-4)"
        alignItems="center"
        pl={3}
        my={2}
      >
        <Typography color="var(--text-default-success)" variant="label-regular">
          {t('try_it.amended')}
        </Typography>
        <PencilLineIcon size={16} color="var(--icon-default-success)" />
      </Box>
    );
  }

  const handleAmendClick = () => {
    setShow((prev) => !prev);
    trackEvent(EventName.ClickAddTrainingPhrase, {
      session_id: amendPayload.session_id,
    });
  };
  return (
    <>
      <MuiAccordion
        sx={{
          ...muiStyles.accordionRoot,
          paddingLeft: '22px',
          paddingTop: 'var(--space-4)',
          paddingBottom: 'var(--space-8)',
          marginTop: '0px !important',
        }}
        expanded={show}
      >
        <Button
          variant="tertiary"
          size="small"
          onClick={handleAmendClick}
          noGutters
          disabled={!canWrite}
        >
          <Typography variant="label-semi-bold">{t('try_it.amend')}</Typography>
          <Box ml={2}>
            <PencilLineIcon
              size={16}
              color={
                canWrite
                  ? 'var(--cta-default-blue)'
                  : 'var(--icon-disabled-gray)'
              }
            />
          </Box>
        </Button>
        <AccordionDetails sx={muiStyles.detailsRoot}>
          <AutocompleteNew
            label={t('common.intent')}
            control={control}
            options={[...options, ...otherIntents]}
            name="intent"
            placeholder={t('try_it.select_intent')}
            width={390}
            enableNewEntry
            groupByLabelProp={false}
            groupByProp="type"
            freeSolo
          />
          <Typography variant="label-regular">
            {t('try_it.message_phrase')}
          </Typography>
          <div className={styles.buttonsContainer}>
            <Button variant="tertiary" onClick={() => setShow(false)}>
              {t('common.cancel')}
            </Button>
            <Tooltip
              arrow
              title={
                trainingPhraseExists ? t('training_phrases.error') : undefined
              }
            >
              <span>
                <Button
                  variant="secondary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={trainingPhraseExists}
                  isLoading={isAmending || updateStatus === 'pending'}
                >
                  {t('common.submit')}
                </Button>
              </span>
            </Tooltip>
          </div>
        </AccordionDetails>
      </MuiAccordion>
    </>
  );
};
