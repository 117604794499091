import React from 'react';

import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { ChevronDownIcon, SearchIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { ExternalUrl, Fragment, TryIt } from '@/models/tryIt';
import { LogMessage } from '@/modules/analytics/models';
import { muiStyles } from '@/modules/TryIt/utils/helper';

import { DocumentAmend } from './DocumentAmend';
import { DocumentLine } from '../../LogSections/DetailsSection/CollectionDetailsSection/DocumentLine';

import styles from '../../LogSections/DetailsSection/DetailsSection.module.scss';

interface Props {
  nodes: TryIt['selectedMessage']['nodes'];
  messages: LogMessage[];
  index: number;
  uniqueDocuments: Fragment[];
  external_urls?: ExternalUrl[];
  onClick?: () => void;
  amended?: boolean;
  isAmending?: boolean;
}

export const DocumentsMatched = ({
  nodes,
  messages,
  index,
  uniqueDocuments,
  external_urls,
  onClick,
  amended,
}: Props) => {
  const { t } = useTranslation();

  const foundDocuments = external_urls?.filter((url) => !!url.document_id);

  if (!foundDocuments || foundDocuments?.length === 0) {
    return (
      <>
        <div className={styles.title}>
          <SearchIcon size={16} color="var(--icon-default-gray)" />

          <Typography
            variant="body-regular"
            color="var(--text-default-gray)"
            component="div"
          >
            {t('try_it.document_retrieved', {
              0: uniqueDocuments.length,
            })}
          </Typography>

          <DocumentAmend amended={amended} onClick={onClick} />
        </div>
        <div className={styles.details}>
          <ol className={styles.retrievedDocuments}>
            {uniqueDocuments.map((datasource) => (
              <DocumentLine
                key={datasource.document_id}
                collectionId={
                  nodes[index]?.collection?.collection_id ??
                  messages?.[index]?.collection_id
                }
                datasourceId={datasource.datasource_id}
                documentId={datasource.document_id}
              />
            ))}
          </ol>
        </div>
      </>
    );
  }
  const otherDocumentsMatched = uniqueDocuments.filter((d) => {
    return !foundDocuments?.find((f) => f.document_id === d.document_id);
  });

  return (
    <>
      <div className={styles.title}>
        <SearchIcon size={16} color="var(--icon-default-gray)" />

        <Typography color="var(--text-default-gray)" component="div">
          {t('try_it.document_selected', {
            0: foundDocuments?.length,
          })}
        </Typography>
        <DocumentAmend amended={amended} onClick={onClick} />
      </div>
      <div className={styles.details}>
        <ol className={cn(styles.retrievedDocuments, styles.usedDocuments)}>
          {foundDocuments?.map((document) => (
            <DocumentLine
              key={document.document_id}
              collectionId={
                nodes[index]?.collection?.collection_id ??
                messages?.[index]?.collection_id
              }
              datasourceId={document.datasource_id}
              documentId={document.document_id}
              isSelected
            />
          ))}
        </ol>
        {otherDocumentsMatched.length > 0 && (
          <MuiAccordion
            sx={{ ...muiStyles.accordionRoot, paddingLeft: 'var(--space-20)' }}
          >
            <AccordionSummary
              expandIcon={
                <ChevronDownIcon
                  color="var(--icon-default-gray)"
                  size={16}
                  data-testid="expand-accordion"
                />
              }
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography color="var(--text-default-gray)">
                  {t('try_it.all_documents_retrieved')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={muiStyles.detailsRoot}>
              <ol className={styles.retrievedDocuments}>
                {otherDocumentsMatched.map((document) => (
                  <DocumentLine
                    key={document.document_id}
                    collectionId={
                      nodes[index]?.collection?.collection_id ??
                      messages?.[index]?.collection_id
                    }
                    datasourceId={document.datasource_id}
                    documentId={document.document_id}
                  />
                ))}
              </ol>
            </AccordionDetails>
          </MuiAccordion>
        )}
      </div>
    </>
  );
};
