import React, { CSSProperties, useCallback } from 'react';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { BotIcon, ChevronRightIcon, CircleCheckIcon } from 'lucide-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import { Badge } from '@/components/atoms/Badge/Badge';
import { getIntegrationByType } from '@/components/organisms/Integrations';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useUser from '@/hooks/useUser';
import { IntegrationType } from '@/models/integration';
import { RootState } from '@/models/state';
import {
  selectAllAgents,
  selectLast3ViewersBySessionId,
} from '@/redux/presence/selectors';
import { isKeyEnter, preventClickThrough } from '@/util/util';

import { timeFormat } from '../../utils';

import styles from './MessagePreview.module.scss';

export interface MessagePreviewProps {
  isSelected?: boolean;
  timestamp: string;
  userName: string;
  message?: string | React.ReactNode;
  channel: IntegrationType;
  unreadCount?: number;
  onClick: (sessionId: string) => void;
  sessionId: string;
  assigneeAgentId: string;
  assigneeBrainId: string;
  status: string;
  style: CSSProperties;
}

const MessagePreview = ({
  isSelected = false,
  timestamp,
  userName,
  message = '',
  channel,
  unreadCount = 0,
  onClick,
  sessionId,
  assigneeAgentId,
  assigneeBrainId,
  status,
  style,
}: MessagePreviewProps) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { ai_agents } = useFeatureFlag();
  const Icon = getIntegrationByType(channel)?.Icon;
  const members = useSelector(selectAllAgents);
  const currentUser = members?.find((x) => x.agent_id === assigneeAgentId);

  const { viewers } = useSelector((state: RootState) =>
    selectLast3ViewersBySessionId(state, sessionId)
  );

  const filteredViewers = viewers?.filter(
    (viewer) => viewer.user_id !== user?.user_id
  );
  const { brain } = useBrains(assigneeBrainId);

  const isStatusOpen = status === 'open';

  const handleClick = useCallback(() => {
    onClick(sessionId);
  }, [onClick, sessionId]);

  const handleKeyDown = useCallback(
    (e) => {
      if (isKeyEnter(e)) {
        preventClickThrough(e);
        onClick(sessionId);
      }
    },
    [onClick, sessionId]
  );

  const renderAssignee = () => {
    if (assigneeAgentId && !assigneeBrainId) {
      return (
        <Tooltip arrow title={currentUser?.name}>
          <span>
            <Avatar userId={assigneeAgentId} size="small" hideStatus />
          </span>
        </Tooltip>
      );
    }
    if (assigneeBrainId && !assigneeAgentId) {
      return (
        <Tooltip
          arrow
          title={t(`conversation.with_name${ai_agents ? '_ai_agent' : ''}`, {
            0: brain?.name,
          })}
        >
          <span>
            <BotIcon
              size={16}
              color={isSelected ? 'var(--icon-default-white)' : undefined}
            />
          </span>
        </Tooltip>
      );
    }
    return t('conversation.unassigned');
  };

  const renderIcon = () => {
    return (
      Icon && (
        <span className={styles.channels}>
          <Tooltip arrow title={t(`channels.${channel}`)}>
            <Icon size={14} />
          </Tooltip>
        </span>
      )
    );
  };

  const getMessageTypographyVariant = (unreadCount, isSelected) => {
    if (unreadCount && !isSelected) return 'body-medium';
    return isSelected ? 'body-medium' : 'body-regular';
  };
  return (
    <div
      className={cn(styles.container, {
        [styles.unreadMessages]: unreadCount && !isSelected,
        [styles.active]: isSelected,
      })}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      style={style}
    >
      <div className={styles.levelOne}>
        <span className={styles.levelOneLeft}>
          <Typography
            className={styles.clientName}
            variant="label-semi-bold"
            color={
              isSelected
                ? 'var(--text-default-white)'
                : 'var(--text-default-gray)'
            }
          >
            {userName}
          </Typography>

          <ChevronRightIcon size={18} color="currentColor" />

          <Typography
            className={styles.userName}
            variant="label-semi-bold"
            color={
              isSelected
                ? 'var(--text-default-white)'
                : 'var(--text-default-gray)'
            }
          >
            {renderAssignee()}
          </Typography>

          {renderIcon()}
        </span>
      </div>

      <div className={styles.levelTwo}>
        <Typography
          className={styles.message}
          variant={getMessageTypographyVariant(unreadCount, isSelected)}
        >
          {message}
        </Typography>

        <Badge
          content={unreadCount}
          color={isSelected ? 'secondary' : 'primary'}
          sx={{ marginLeft: 'auto', marginRight: '11px' }}
        >
          {unreadCount ? (
            <Tooltip arrow title={t('conversation.unread')}>
              <span />
            </Tooltip>
          ) : null}
        </Badge>
      </div>

      <div className={styles.levelThree}>
        {!isStatusOpen && (
          <Tooltip arrow title={t('conversation.resolved')}>
            <span className={styles.status}>
              <CircleCheckIcon
                color={isSelected ? 'var(--icon-default-white)' : undefined}
                size={12}
              />
            </span>
          </Tooltip>
        )}

        {filteredViewers?.length > 0 && (
          <span className={styles.viewers}>
            <Tooltip
              arrow
              title={filteredViewers?.reduce((acc, viewer, index) => {
                const viewerName = members?.find(
                  (x) => x.agent_id === viewer.user_id
                )?.name;
                if (index === 0) {
                  return viewerName;
                }
                return `${acc}, ${viewerName}`;
              }, '')}
              key="viewer-tooltip"
            >
              <span>
                <Typography
                  variant="label-regular"
                  color={
                    isSelected
                      ? 'var(--icon-default-white)'
                      : 'var(--icon-default-gray)'
                  }
                >
                  {t('conversation.viewing', { 0: filteredViewers.length })}
                </Typography>
              </span>
            </Tooltip>
          </span>
        )}

        <Box component="span" sx={{ marginLeft: 'auto' }}>
          {timestamp && (
            <Typography
              color={
                isSelected
                  ? 'var(--icon-default-white)'
                  : 'var(--text-default-gray)'
              }
              variant="label-caps-small"
            >
              {timeFormat(moment(timestamp).fromNow())}
            </Typography>
          )}
        </Box>
      </div>
    </div>
  );
};

export default MessagePreview;
