import { memo, useCallback, useEffect, useRef, useState } from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { pdf } from '@react-pdf/renderer';
import cn from 'classnames';
import { saveAs } from 'file-saver';
import { toPng } from 'html-to-image';
import { RefreshCcwIcon, UploadIcon, XIcon } from 'lucide-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Loading from '@/components/atoms/Loading/Loading';
import Spinner from '@/components/atoms/Spinner/Spinner';
import Tab from '@/components/atoms/Tab/Tab';
import TabPanel from '@/components/atoms/TabPanel/TabPanel';
import Tabs from '@/components/atoms/Tabs/Tabs';
import Modal from '@/components/organisms/Modals/Modal';
import {
  RatingSection,
  SessionSection,
  UserSection,
} from '@/components/organisms/Modals/ModalTryIt/LogSections';
import { TestSessionSection } from '@/components/organisms/Modals/ModalTryIt/LogSections/SessionSection/TestSessionSection';
import useBrains from '@/hooks/useBrains';
import useLogs from '@/hooks/useLogs';
import { useModalTryIt } from '@/hooks/useModalTryIt';
import { EventName } from '@/models/segment';
import { NavigationItem } from '@/models/tryIt';
import { popModal } from '@/redux/modals/actions';
import { trackEvent } from '@/segment/segment';

import {
  resetSelectedMessage,
  setSessionNodes,
  setSessionSource,
  updateSessionId,
} from '../../redux/actions';
import {
  selectIsTryItReplay,
  selectTryItSessionId,
} from '../../redux/selectors';
import ContextSection from '../ContextSection';
import { DetailsSection } from '../LogSections/DetailsSection/DetailsSection';
import { Messages } from '../Messages/Messages';
import { PdfExport } from '../PdfExport/PdfExport';

import styles from './ModalTryIt.module.scss';

type Props = {
  brainId: string;
  sessionId?: string;
  isAccountReplay?: boolean;
  tryItSessionId?: string;
};

const MODAL_HEIGHT = '650px';
const MODAL_WIDTH = '1000px';

// Sub component
const ModalTryItInformation = ({
  navigationItem,
  content,
  isLoading,
  tryItSessionId,
  isReplay,
}) => (
  <>
    <TabPanel value={navigationItem} index="details">
      <DetailsSection content={content} />
    </TabPanel>
    <TabPanel value={navigationItem} index={'user'}>
      <UserSection />
    </TabPanel>
    <TabPanel value={navigationItem} index={'conversation'}>
      {isReplay ? (
        <SessionSection isLoading={isLoading} />
      ) : (
        <TestSessionSection sessionId={tryItSessionId} />
      )}
    </TabPanel>
    <TabPanel value={navigationItem} index={'context'}>
      <ContextSection />
    </TabPanel>

    <TabPanel value={navigationItem} index={'rating'}>
      <RatingSection />
    </TabPanel>
  </>
);

export const ModalTryIt = memo(
  ({ brainId, sessionId, isAccountReplay }: Props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { headerItems, clearSessionIdFromUrl } = useModalTryIt(
      sessionId,
      isAccountReplay
    );
    const isReplay = useSelector(selectIsTryItReplay);
    const screenshotRef = useRef(null);
    const [isScreenshotLoading, setIsScreenshotLoading] = useState(false);
    const { brain } = useBrains(brainId);
    const session_id = sessionId ?? undefined;
    const { content, isLoading } = useLogs(undefined, session_id);
    const { brain: logBrain } = useBrains(content?.brain_id);

    const tryItSessionId = useSelector(selectTryItSessionId);

    const [navigationItem, setNavigationItem] =
      useState<NavigationItem>('details');

    const downloadImage = async (image: string) => {
      const finalFile = <PdfExport content={content} snapshot={image} />;

      const asPdf = pdf(<></>);
      asPdf.updateContainer(finalFile);
      const blob = await asPdf.toBlob();
      saveAs(
        blob,
        `${moment().format('YYYY-MM-DD')}-session-${content.session_id}.pdf`
      );
    };

    const handleConversationDownload = async () => {
      trackEvent(EventName.ClickAnalyticsLogsExport, { session_id });
      setIsScreenshotLoading(true);
      try {
        const dataUrl = await toPng(screenshotRef.current, {
          quality: 1,
          backgroundColor: 'white', // Ensures transparent areas are white instead of black
          cacheBust: true,
          skipFonts: true,
        });
        downloadImage(dataUrl);
      } catch (error) {
        console.error('Error taking screenshot:', error);
      }
      setIsScreenshotLoading(false);
    };

    const handleCloseModal = useCallback(() => {
      dispatch(popModal());
      navigate(clearSessionIdFromUrl(), {
        replace: true,
      });
    }, [dispatch, clearSessionIdFromUrl, navigate]);

    const handleReset = () => {
      dispatch(setSessionNodes([]));
      dispatch(resetSelectedMessage());
      dispatch(updateSessionId(uuidv4()));
    };
    // Render methods
    const renderTitle = () => {
      if (isReplay) {
        return content?.user_name ?? t('common.anonymous');
      }
      return brain?.name;
    };
    useEffect(() => {
      if (content && (logBrain || brain)) {
        dispatch(
          setSessionSource({
            deskId: content?.desk_id,
            integrationId: content?.integration_id,
            channel: content?.channel,
            sessionId: content?.session_id || tryItSessionId,
            language: logBrain?.language || brain?.language,
          })
        );
      }
    }, [brain, content, dispatch, logBrain, tryItSessionId]);

    const activeBrainId = brainId || logBrain?.brain_id;
    return (
      <Modal
        header={false}
        passiveModal
        size="large"
        noGutters
        cleanModal
        onBlur={() => {
          navigate(clearSessionIdFromUrl(), {
            replace: true,
          });
        }}
      >
        <Container
          sx={{
            display: 'flex',
            height: MODAL_HEIGHT,
            width: MODAL_WIDTH,
          }}
          disableGutters
          maxWidth="xl"
        >
          <Box component="section" className={styles.leftColumn}>
            <div className={styles.heading}>
              <Typography
                variant="body-semi-bold"
                color="var(--text-default-gray)"
                py="calc(var(--space-12) / 2)"
              >
                {renderTitle()}
              </Typography>
              <div className={styles.buttonContainer}>
                {isReplay && (
                  <>
                    {isScreenshotLoading ? (
                      <Spinner className={styles.spinner} />
                    ) : (
                      <IconButton
                        onClick={handleConversationDownload}
                        ariaLabel={t('try_it.download_conversation_label')}
                        tooltip={t('try_it.download_conversation_tooltip')}
                      >
                        <UploadIcon
                          size={16}
                          color="var(--icon-default-blue)"
                        />
                      </IconButton>
                    )}
                  </>
                )}
                {!isReplay ? (
                  <IconButton
                    onClick={handleReset}
                    ariaLabel={t('try_it.reset')}
                  >
                    <RefreshCcwIcon
                      size={16}
                      color="var(--icon-default-gray)"
                    />
                  </IconButton>
                ) : (
                  <CopyButton className="primary" data={window.location.href} />
                )}
              </div>
            </div>

            {isReplay && isLoading && <Loading />}
            {isReplay && !isLoading && (
              <Messages
                brainId={activeBrainId}
                messages={content?.messages}
                tags={content?.tags}
                isAccountReplay={isAccountReplay}
                ref={screenshotRef}
              />
            )}
            {!isReplay && (
              <Messages brainId={activeBrainId} ref={screenshotRef} />
            )}
          </Box>

          <Box component="section" className={styles.rightColumn}>
            <div className={cn(styles.heading, styles['heading--tabs'])}>
              <Tabs
                onChange={(_, newValue) => {
                  setNavigationItem(newValue);
                }}
                value={navigationItem}
              >
                {headerItems?.map((name) => (
                  <Tab
                    key={name}
                    value={name}
                    label={t(`try_it.headers.${name}`)}
                  />
                ))}
              </Tabs>

              <IconButton
                onClick={handleCloseModal}
                ariaLabel={t('modals.close')}
                className={styles.closeButton}
              >
                <XIcon color="var(--icon-default-black)" />
              </IconButton>
            </div>
            <Box p="var(--space-16)">
              <ModalTryItInformation
                navigationItem={navigationItem}
                content={content}
                isLoading={isLoading}
                tryItSessionId={tryItSessionId}
                isReplay={isReplay}
              />
            </Box>
          </Box>
        </Container>
      </Modal>
    );
  }
);

ModalTryIt.displayName = 'ModalTryIt';
