import { useState } from 'react';

import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  ChevronDownIcon,
  CrosshairIcon,
  LocateFixedIcon,
  SparklesIcon,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { useAccount } from '@/hooks/useAccount';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { AmendPayload } from '@/models/tryIt';
import { muiStyles } from '@/modules/TryIt/utils/helper';
import { popModal } from '@/redux/modals/actions';
import { resolveBrainsPath } from '@/util/util';

import { AmendIntent } from './AmendIntent';

import styles from '../../LogSections/DetailsSection/DetailsSection.module.scss';

interface Props {
  brainId: string;
  isPlannerIntentOfftopic: boolean;
  intentName?: string;
  plannerIntent?: string;
  possibleMatchedIntents?: {
    intent: string;
    confidence: number;
  }[];
  showAddTrainingPhrase?: boolean;
  userMessage?: string;
  amendPayload?: AmendPayload;
  isAmended?: boolean;
}

export const PossibleMatchedIntents = ({
  brainId,
  isPlannerIntentOfftopic,
  intentName,
  plannerIntent,
  possibleMatchedIntents,
  showAddTrainingPhrase,
  userMessage,
  amendPayload,
  isAmended,
}: Props) => {
  const { slug } = useAccount();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ai_agents } = useFeatureFlag();
  const [amended, setAmended] = useState(isAmended);

  const closeModal = () => {
    dispatch(popModal());
  };
  const intent = plannerIntent || intentName;

  const isOfftopic = intent === 'offtopics' || isPlannerIntentOfftopic;

  const filteredMatchedIntents = possibleMatchedIntents?.filter(
    (intent) => intent.intent !== plannerIntent && intent.intent !== intentName
  );

  const matchedPlannerIntent = possibleMatchedIntents?.find(
    (intent) => intent.intent === plannerIntent || intentName
  );

  return (
    <>
      <div className={styles.title}>
        {isOfftopic ? (
          <>
            <CrosshairIcon size={16} color="var(--icon-default-gray)" />
            <Typography color="var(--text-default-gray)" component="div">
              {t('try_it.no_intent_ai_agent')}
            </Typography>
          </>
        ) : (
          <>
            <div className={styles.iconWrapper}>
              <LocateFixedIcon size={16} color="var(--icon-default-gray)" />
            </div>
            <div className={styles.wrapper}>
              <Typography color="var(--text-default-gray)">
                {t('common.intent')}{' '}
              </Typography>
              <Box className={styles.plannerIntent}>
                <SparklesIcon size={16} color="var(--icon-default-purple)" />
                <NavLink
                  to={resolveBrainsPath(
                    `/${slug}/brains/${brainId}/intents/${intent}`,
                    ai_agents
                  )}
                  className={styles.link}
                  onClick={closeModal}
                >
                  <Typography variant="body-regular">{intent}</Typography>
                </NavLink>
              </Box>{' '}
              <Typography color="var(--text-default-gray)">
                {t('try_it.selected_ai_agent', {
                  0: (matchedPlannerIntent?.confidence * 100).toFixed(0),
                })}
              </Typography>
            </div>
          </>
        )}
      </div>
      <div className={styles.details}>
        {filteredMatchedIntents?.length > 0 && (
          <MuiAccordion
            sx={{
              ...muiStyles.accordionRoot,
              paddingLeft: 'var(--space-8)',
              paddingTop: 'var(--space-4)',
              marginBottom: '0px',
            }}
          >
            <AccordionSummary
              expandIcon={
                <ChevronDownIcon
                  color="var(--icon-default-gray)  "
                  size={16}
                  data-testid="expand-accordion"
                />
              }
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography color="var(--text-default-gray)">
                  {isOfftopic
                    ? t('try_it.considered')
                    : t('try_it.other_considered')}
                  :
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={muiStyles.detailsRoot}>
              <ol className={styles.intentsConsidered}>
                {(isOfftopic
                  ? possibleMatchedIntents
                  : filteredMatchedIntents
                )?.map((intent) => (
                  <Typography
                    color="var(--text-default-gray)"
                    component="div"
                    className={styles.details__content}
                    key={intent.intent}
                  >
                    {t('try_it.possible_match')}
                    {': '}
                    <Typography>
                      <NavLink
                        to={resolveBrainsPath(
                          `/${slug}/brains/${brainId}/intents/${intent.intent}`,
                          ai_agents
                        )}
                        className={styles.link}
                        onClick={closeModal}
                      >
                        {intent?.intent}
                      </NavLink>
                    </Typography>{' '}
                    {t('try_it.confidence_percentage', {
                      0: (intent?.confidence * 100).toFixed(0),
                    })}
                  </Typography>
                ))}
              </ol>
            </AccordionDetails>
          </MuiAccordion>
        )}
        {showAddTrainingPhrase && (
          <AmendIntent
            possibleMatchedIntents={possibleMatchedIntents}
            brainId={brainId}
            userMessage={userMessage}
            amendPayload={amendPayload}
            setAmended={setAmended}
            amended={amended}
          />
        )}
      </div>
    </>
  );
};
