import { useState } from 'react';

import Menu from '@mui/material/Menu';
import { ModalProps } from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import {
  AlertTriangleIcon,
  ChevronDownIcon,
  CircleAlertIcon,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { DialogAlert } from '@/models/dialogAlert';

import AlertSummaryItem from './AlertSummaryItem/AlertSummaryItem';

import styles from './AlertSummary.module.scss';

const MENU_WIDTH = 318;

const muiStyles = {
  '& .MuiList-root': {
    padding: 'var(--space-16) var(--space-8)',
    maxWidth: MENU_WIDTH,
  },
};

type AlertSummaryProps = {
  alertType: 'error' | 'warning';
  items: DialogAlert[];
  selectAlertAction: (alert: DialogAlert) => void;
};

const AlertSummary = ({
  alertType,
  items,
  selectAlertAction,
}: AlertSummaryProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isWarning = alertType === 'warning';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button
        aria-controls={open ? `${alertType}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={cn(styles.wrapper, {
          [styles['wrapper--open']]: open,
          [styles['warning']]: isWarning,
        })}
        id={`${alertType}-button`}
      >
        {isWarning ? (
          <CircleAlertIcon color="var(--icon-default-warning)" size={14} />
        ) : (
          <AlertTriangleIcon size={16} color="var(--icon-default-error)" />
        )}
        <Typography variant="body-semi-bold">
          {t(`dialog.alerts.${alertType}`, {
            count: items.length,
          })}
        </Typography>
        <ChevronDownIcon color="var(--icon-default-gray)" size={18} />
      </button>

      <Menu
        variant="menu"
        sx={muiStyles}
        slotProps={{
          root: {
            slotProps: {
              backdrop: { style: { opacity: '0%' } },
            } as ModalProps['slotProps'],
          },
        }}
        id={`${alertType}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `${alertType}-button`,
          autoFocus: true,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -3,
          horizontal: 'right',
        }}
      >
        {items.map(({ id, type, title, body, ...rest }) => (
          <AlertSummaryItem
            key={`${id}-${rest.alertField}`}
            type={type}
            title={title}
            body={body}
            handleClick={() => {
              handleClose();
              selectAlertAction({
                id,
                type,
                title,
                body,
                ...rest,
              } as DialogAlert);
            }}
          />
        ))}
      </Menu>
    </>
  );
};

export default AlertSummary;
