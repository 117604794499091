import {
  ChangeEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Option } from '@/components/atoms/AutoComplete/AutoComplete';
import NewAutocomplete from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import Select from '@/components/atoms/Select/Select';
import ToolkitAccordionWrapper from '@/components/organisms/Toolkit/ToolkitAccordionWrapper';
import { useBrainVariables } from '@/hooks/useBrainVariables';
import useDialogs from '@/hooks/useDialogs';
import useToolkitRule from '@/hooks/useToolkitRule';
import { VariableOption } from '@/models/autocomplete';
import { Rule } from '@/models/node';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { addDollarSign, capitalizeFirstLetter } from '@/util/util';
import { toolkitConditionRuleSchema } from '@/util/validator';

import styles from './ToolkitRule.module.scss';
type FormType = {
  name: Option;
  operator?: string;
  value_auto: Option;
};

interface ToolkitRuleProps {
  rule: Rule;
  onChange: (
    values: {
      name?: string;
      operator?: string;
      value?: string;
    },
    index: number
  ) => void;
  onDelete: (index: number) => void;
  index: number;
  nodeId: string;
  conditionId: string;
  conditionIndex: string;
}

const ToolkitRule = ({
  rule,
  onChange,
  onDelete,
  index,
  nodeId,
  conditionId,
  conditionIndex,
}: ToolkitRuleProps) => {
  const brainId = useSelector(selectBrainId);
  const { getContextVariables } = useDialogs(brainId);
  const variables = getContextVariables();
  const { entityVariables } = useBrainVariables(false);
  const autoCompleteOptions = useMemo(() => {
    return (
      [...variables, ...entityVariables]
        // Filter out the live instructions variable
        .filter((variable) => variable?.value !== '$live_instructions')
    );
  }, [entityVariables, variables]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    hasValue,
    availableDesks,
    getDefaultRuleValue,
    availableValues,
    operators,
    enableNewEntry,
  } = useToolkitRule(rule);

  // RHF Default values
  const defaultValues = useMemo(
    () => ({
      name:
        autoCompleteOptions.find((option) => rule?.name === option.value) ??
        null,
      operator: rule.operator,
      value_auto: {
        label:
          rule.name !== '$sys-desk'
            ? rule.value
            : availableDesks.find((desk) => desk.value === rule.value)?.label,
        value: rule.value,
        type: 'value',
      },
    }),
    [autoCompleteOptions, availableDesks, rule.name, rule.operator, rule.value]
  );

  const {
    formState: { errors, isDirty },
    register,
    unregister,
    setValue,
    control,
    getValues,
    trigger,
  } = useForm<FormType>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(toolkitConditionRuleSchema) as Resolver<FormType>,
  });
  // By default show open the first rule
  const [open, setOpen] = useState(index === 0);
  const onPanelOpen = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  useEffect(() => {
    // Opens the first rule when there are errors
    // It is used inside useEffect and not in the
    // useState of open, because rhf errors initially
    // is an empty object
    setOpen(index === 0 || !isEmpty(errors));
  }, [errors, index]);

  // Extract error messages from the form state
  const nameErrorMessage = capitalizeFirstLetter(errors.name?.message);
  const valueErrorMessage = capitalizeFirstLetter(
    errors.value_auto?.message || errors.value_auto?.value?.message
  );

  const onRuleNameChange = useCallback(
    (_, option: VariableOption) => {
      // Allows user to type a variable name without the dollar sign
      const updatedName = option?.value.startsWith('@')
        ? option.value
        : addDollarSign(option?.value ?? '');
      const value_auto = getDefaultRuleValue(updatedName);
      const updatedRule = {
        name: updatedName,
        value: value_auto.value,
        operator: operators?.[0]?.value,
      };

      if (option) {
        // Update the default value only when the user has selected a variable
        register('value_auto');
        setValue('value_auto', value_auto, {
          shouldValidate: true,
        });
        setValue('operator', operators?.[0]?.value);
      }

      onChange(updatedRule, index);
    },
    [getDefaultRuleValue, index, onChange, operators, register, setValue]
  );

  const onOperatorChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (event) => {
      const updatedRule = {
        operator: event.target.value,
      };

      const operator = getValues('operator');
      if (operator === 'exist' || operator === 'not_exist') {
        // Clear value when operator is exist or not_exist
        updatedRule['value'] = undefined;
      } else if (!getValues('value_auto') && getValues('name')) {
        const name = getValues('name');
        const defaultValue = getDefaultRuleValue(name?.value);

        updatedRule['value'] = defaultValue.value;

        setValue('value_auto', defaultValue, {
          shouldValidate: true,
        });
      }

      onChange(updatedRule, index);
    },
    [getDefaultRuleValue, getValues, index, onChange, setValue]
  );

  const updateErrors = useCallback(
    (key: string, value: string) => {
      // Prevents from unnecessary redux state updates
      if (!isDirty) return;

      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: conditionId,
            title: t('actions.types.condition'),
            body: value,
            type: 'condition',
            nodeId,
            index: conditionIndex,
            alertField: key,
          },
        })
      );
    },
    [conditionId, conditionIndex, dispatch, isDirty, nodeId, t]
  );

  const handleDelete = useCallback(() => {
    // Clear errors
    updateErrors(`name-of-rule-${index}-in-condition-${conditionIndex}`, '');
    updateErrors(`value-of-rule-${index}-in-condition-${conditionIndex}`, '');

    onDelete(index);
  }, [conditionIndex, index, onDelete, updateErrors]);

  useEffect(() => {
    if (!hasValue(getValues('operator'))) {
      unregister('value_auto');
      updateErrors(`value-of-rule-${index}-in-condition-${conditionIndex}`, '');
    }
  }, [conditionIndex, getValues, hasValue, index, unregister, updateErrors]);

  // Dialog errors
  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    updateErrors(
      `name-of-rule-${index}-in-condition-${conditionIndex}`,
      nameErrorMessage
    );
  }, [conditionIndex, index, nameErrorMessage, updateErrors]);

  useEffect(() => {
    updateErrors(
      `value-of-rule-${index}-in-condition-${conditionIndex}`,
      valueErrorMessage
    );
  }, [conditionIndex, index, updateErrors, valueErrorMessage]);

  return (
    <div className={styles.wrapper} key={rule.rule_id}>
      <ToolkitAccordionWrapper
        title={t('dialog.rule_with_number', { 0: index + 1 })}
        subTitle={getValues('name.label') ?? ''}
        onChange={onPanelOpen}
        handleDelete={handleDelete}
        expanded={open}
        hideReorderIcon
        disabled={!onDelete}
      >
        <div className={styles.wrapper__item}>
          <NewAutocomplete
            id={`rule-name-${index}`}
            name="name"
            control={control}
            freeSolo
            enableNewEntry
            options={autoCompleteOptions}
            label={t('dialog.condition.check_if')}
            tooltip={t('dialog.toolkit_rule_tooltip')}
            placeholder={t('dialog.condition.name_placeholder')}
            size="xs"
            onChange={onRuleNameChange}
            groupByProp="type"
            hasError={!!nameErrorMessage}
            errorMessage={nameErrorMessage}
            getOptionLabel={(option) => option?.label ?? option}
            width={278}
          />
        </div>

        <div className={styles.wrapper__item}>
          <Select
            id={`rule-operator-${index}`}
            onChange={onOperatorChange}
            error={!!errors.operator}
            placeholder={t('dialog.type_operator')}
            name="operator"
            options={operators}
            register={register('operator')}
            size="small-full"
          />
        </div>

        {hasValue(getValues('operator')) && (
          <NewAutocomplete
            id={`rule-value-${index}`}
            name="value_auto"
            control={control}
            freeSolo={availableValues.length === 0}
            enableNewEntry={enableNewEntry}
            options={availableValues}
            groupByProp="type"
            placeholder={
              availableValues.length > 0
                ? t('common.select_an_option')
                : t('common.type_value')
            }
            size="xs"
            onChange={(_, option) => {
              // Updates redux when user selects an option from the list
              if (availableValues.length > 0) {
                onChange(
                  {
                    value: option?.value,
                  },
                  index
                );
              }
            }}
            onInputChange={(_, value) => {
              // Updates redux when user types a value
              if (availableValues.length === 0) {
                // Prevents from unnecessary redux state updates
                if (value === getValues('value_auto.value')) return;

                setValue(
                  'value_auto',
                  {
                    label: value,
                    value,
                    type: 'value',
                  },
                  {
                    shouldValidate: true,
                  }
                );

                onChange(
                  {
                    value,
                  },
                  index
                );
              }
            }}
            groupByLabelProp={false}
            width={278}
            getOptionLabel={(option) => option?.label ?? option}
            hasError={!!valueErrorMessage}
            errorMessage={valueErrorMessage}
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
          />
        )}
      </ToolkitAccordionWrapper>
    </div>
  );
};

export default memo(ToolkitRule);
