import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import Input from '@/components/atoms/Input/Input';
import { UserMessage } from '@/models/tryIt';
import { selectSelectedMessage } from '@/modules/TryIt/redux/selectors';

import { ConversationIdSection } from './ConversationIdSection';

import styles from './SessionSection.module.scss';

type Props = {
  sessionId: string;
};

export const TestSessionSection = ({ sessionId }: Props) => {
  const { t } = useTranslation();

  const selectedNodes = useSelector(selectSelectedMessage);
  const { nodes, index } = selectedNodes;
  const selectedMessage = nodes[index];

  const request_id =
    selectedMessage?.request_id ||
    (selectedMessage?.messages?.[0] as UserMessage)?.request_id;
  return (
    <>
      <ConversationIdSection>
        <div className={styles.idContainer}>
          <Input
            readOnly
            value={sessionId}
            label={t('analytics.fields.session_id')}
            size="small"
          />

          <CopyButton className={styles.copyButton} data={sessionId} />
        </div>
      </ConversationIdSection>
      {request_id && (
        <div className={styles.idContainer}>
          <Input
            readOnly
            value={request_id}
            label={t('analytics.fields.request_id')}
            size="small"
          />

          <CopyButton className={styles.copyButton} data={request_id} />
        </div>
      )}
    </>
  );
};
