import { useCallback, useState } from 'react';

import { cn } from '@udecode/cn';
import { useFormInputProps } from '@udecode/plate/react';
import {
  type UseVirtualFloatingOptions,
  flip,
  offset,
} from '@udecode/plate-floating';
import {
  type LinkFloatingToolbarState,
  FloatingLinkUrlInput,
  LinkOpenButton,
  useFloatingLinkEdit,
  useFloatingLinkEditState,
  useFloatingLinkInsert,
  useFloatingLinkInsertState,
} from '@udecode/plate-link/react';
import { ExternalLinkIcon, LinkIcon, TextIcon, UnlinkIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { preventClickThrough } from '@/util/util';
import { urlPattern } from '@/util/validator';

import { buttonVariants } from './button';
import { inputVariants } from './input';
import { popoverVariants } from './popover';
import { Separator } from './separator';

const floatingOptions: UseVirtualFloatingOptions = {
  middleware: [
    offset(12),
    flip({
      fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
      padding: 12,
    }),
  ],
  placement: 'bottom-start',
};

export interface LinkFloatingToolbarProps {
  state?: LinkFloatingToolbarState;
}

export function LinkFloatingToolbar({ state }: LinkFloatingToolbarProps) {
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();
  const handleValidateUrl = useCallback((event) => {
    const url = event.target.value;

    if (event.key === 'Enter') {
      const isValidUrl = urlPattern.test(url);
      if (!isValidUrl) {
        preventClickThrough(event);
      }
      setIsError(!isValidUrl);
    }
  }, []);

  const handleKeyUp = useCallback((event) => {
    const url = event.target.value;
    if (url === '') {
      setIsError(false);
    }
  }, []);

  const insertState = useFloatingLinkInsertState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  });
  const {
    hidden,
    props: insertProps,
    ref: insertRef,
    textInputProps,
  } = useFloatingLinkInsert(insertState);

  const editState = useFloatingLinkEditState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  });
  const {
    editButtonProps,
    props: editProps,
    ref: editRef,
    unlinkButtonProps,
  } = useFloatingLinkEdit(editState);
  const inputProps = useFormInputProps({
    preventDefaultOnEnterKeydown: true,
  });

  if (hidden) return null;
  const input = (
    <div className="flex w-[330px] flex-col" {...inputProps}>
      <div className="flex items-center">
        <div className="flex items-center pl-2 pr-1 text-muted-foreground">
          <LinkIcon
            className="size-4"
            color={`${isError ? 'red' : 'currentColor'}`}
          />
        </div>

        <FloatingLinkUrlInput
          className={`
                                                ${inputVariants({ h: 'sm', variant: 'ghost' })}
                                                ${isError ? 'border-red-500 focus-visible:ring-transparent focus-visible:ring-offset-transparent' : ''}
                                        `}
          placeholder={t('rich_text_editor.add_link')}
          data-plate-focus
          onKeyDown={handleValidateUrl}
          onKeyUp={handleKeyUp}
          onBlur={() => setIsError(false)}
        />
      </div>
      <Separator className="my-1" />
      <div className="flex items-center">
        <div className="flex items-center pl-2 pr-1 text-muted-foreground">
          <TextIcon className="size-4" />
        </div>
        <input
          className={inputVariants({ h: 'sm', variant: 'ghost' })}
          placeholder={t('rich_text_editor.add_link_text')}
          data-plate-focus
          {...textInputProps}
        />
      </div>
    </div>
  );

  const editContent = editState.isEditing ? (
    input
  ) : (
    <div className="box-content flex items-center">
      <button
        className={buttonVariants({ size: 'sm', variant: 'ghost' })}
        type="button"
        {...editButtonProps}
      >
        Edit link
      </button>

      <Separator orientation="vertical" />

      <LinkOpenButton
        className={buttonVariants({
          size: 'icon',
          variant: 'ghost',
        })}
      >
        <ExternalLinkIcon width={18} />
      </LinkOpenButton>

      <Separator orientation="vertical" />

      <button
        className={buttonVariants({
          size: 'icon',
          variant: 'ghost',
        })}
        type="button"
        {...unlinkButtonProps}
      >
        <UnlinkIcon width={18} />
      </button>
    </div>
  );

  return (
    <>
      <div
        ref={insertRef}
        className={cn(popoverVariants(), 'w-auto p-1')}
        {...insertProps}
      >
        {input}
      </div>

      <div
        ref={editRef}
        className={cn(popoverVariants(), 'w-auto p-1')}
        {...editProps}
      >
        {editContent}
      </div>
    </>
  );
}
