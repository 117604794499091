import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useBlocker } from 'react-router-dom';

import { Banner } from '@/components/atoms/Banner/Banner';
import { MODAL_CONFIRM_CHANGES } from '@/components/organisms/Modals/ModalConductor';
import { clearDialogAlerts } from '@/redux/dialogAlerts/actions';
import { selectDialogAlerts } from '@/redux/dialogAlerts/selectors';
import { pushModal } from '@/redux/modals/actions';

const usePrompt = (
  condition: boolean,
  subtitle?: string,
  title?: string,
  handleSave?: (() => void) | (() => Promise<void>),
  protectedRoutes?: string[]
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dialogAlert = useSelector(selectDialogAlerts);
  const dialogErrors = dialogAlert.filter(
    (dialog) => dialog.alertType === 'error'
  );

  // Block navigating elsewhere when data has been entered into the input
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    let blockNavigationProtectedRoutes = true;
    if (protectedRoutes) {
      const isLeavingProtectedRoute = protectedRoutes.includes(
        currentLocation.pathname
      );
      const isNavigatingToProtectedRoute = protectedRoutes.includes(
        nextLocation.pathname
      );
      blockNavigationProtectedRoutes =
        isLeavingProtectedRoute && !isNavigatingToProtectedRoute;
    }
    const blockNavigation =
      condition &&
      currentLocation.pathname !== nextLocation.pathname &&
      blockNavigationProtectedRoutes;

    // returns true to block navigation, false to allow it
    return blockNavigation;
  });

  useEffect(() => {
    if (condition) {
      if (blocker.state === 'blocked') {
        const onDiscard = () => {
          dispatch(clearDialogAlerts({}));
          blocker.proceed();
        };

        const onSave = async () => {
          if (handleSave) {
            await handleSave();
            blocker.proceed();
          }
        };

        const onClose = () => {
          blocker.reset();
        };

        const commonModalProps = {
          onClose,
          onDiscard,
          onSave,
          subtitle,
          title,
        };

        const noDialogErrors = dialogErrors.length === 0;

        // Determines whether to show a standard confirmation modal or an
        // error-specific modal based on the presence of dialog errors.
        if (noDialogErrors) {
          dispatch(pushModal(MODAL_CONFIRM_CHANGES, commonModalProps));
        } else {
          const bannerTitle = t('dialog.alerts.error', {
            0: dialogErrors.length,
          });

          const subtitleElement = React.createElement(
            Banner,
            {
              title: bannerTitle,
              variant: 'attention',
              relativePosition: true,
            },
            t('prompts.unsaved_changes.bannerBody')
          );

          dispatch(
            pushModal(MODAL_CONFIRM_CHANGES, {
              ...commonModalProps,
              onSave: onClose,
              subtitle: subtitleElement,
              primaryText: t('prompts.unsaved_changes.resolve_errors'),
              secondaryText: t('prompts.unsaved_changes.discard_changes'),
            })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker, condition, dialogErrors.length, dispatch, subtitle, t, title]);
  return;
};

export default usePrompt;
