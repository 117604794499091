import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew';
import Input from '@/components/atoms/Input/Input';
import { useSubscribers } from '@/modules/broadcast/hooks/useSubscribers';
import {
  selectBroadcast,
  selectContextVariableOptions,
  selectIsDraft,
} from '@/modules/broadcast/redux/selectors';
import { getContextVariableOptions } from '@/modules/broadcast/utils';
import { Option } from '@/modules/rules/model';

import { Form } from '../../../models';

import styles from './VariablesSection.module.scss';

interface Props {
  name: 'body' | 'header' | 'footer';
  isReadOnly: boolean;
}
export const VariablesSection = ({ name, isReadOnly }: Props) => {
  const { t } = useTranslation();
  const { control, formState, watch } = useFormContext<Form>();
  const { errors } = formState;
  const isDraft = useSelector(selectIsDraft);
  const broadcast = useSelector(selectBroadcast);
  const { subscribers } = useSubscribers(broadcast.broadcast_id);
  const draftSubscribersOptions = useSelector(selectContextVariableOptions);
  const storedSubscribersOptions = getContextVariableOptions(
    subscribers?.pages?.[0].subscribers
  );
  const options = isDraft ? draftSubscribersOptions : storedSubscribersOptions;
  const formName = `${name}Parameters` as const;

  const { fields } = useFieldArray({
    control,
    name: formName,
  });

  return (
    <Box mb={2}>
      <Typography component="p" variant="label-semi-bold">
        {t('try_it.add_variables')}
      </Typography>
      <Typography>{t('broadcasts.broadcast_variables')}</Typography>
      <div className={styles.buttonsContainer}>
        {fields.map((_, i) => {
          const currentValue = watch(`${formName}.${i}.name`) as Option;
          const isVariable = (options as Option[]).includes(currentValue);
          const varPosition = `{{${i + 1}}}`;
          return (
            <div
              key={varPosition}
              className={cn(styles.buttonContainer, styles.withPrefix)}
            >
              <Input readOnly value={varPosition} tabIndex={-1} />
              <div className={cn(styles.fieldWrapper)}>
                <AutocompleteNew
                  groupByProp="type"
                  control={control}
                  options={options}
                  name={`${formName}.${i}.name`}
                  id="template"
                  size="medium"
                  placeholder={t('broadcasts.select_variable', {
                    0: varPosition,
                  })}
                  rules={{ required: t('validation.required') }}
                  hasError={Boolean(errors[formName]?.[i]?.name)}
                  errorMessage={errors[formName]?.[i]?.name?.message}
                  disabled={isReadOnly}
                  getOptionLabel={(option) => {
                    return typeof option === 'string'
                      ? option
                      : option.value.toString();
                  }}
                  inputStyles={
                    isVariable && {
                      '& .MuiAutocomplete-inputRoot': {
                        color: 'var(--text-default-blue)',
                      },
                    }
                  }
                  optionStyles={{ color: 'var(--text-default-blue)' }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </Box>
  );
};
