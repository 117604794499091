import { useCallback, useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';

import { rulesEndpoints } from '@/api/endpoints';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { selectAccountSlug } from '@/redux/session/selectors';
import { convertNameToTag } from '@/util/util';

import { useBroadcasts } from './useBroadcasts';
import { useRtmBroadcast } from './useRtmBroadcast';
import { useSubscribers } from './useSubscribers';
import {
  Form,
  LocationState,
  Broadcast as BroadcastType,
  BroadcastComponentParameterContent,
} from '../models';
import { actions } from '../redux/actions';
import {
  selectBroadcast,
  selectBroadcastDeskId,
  selectIsDraft,
  selectIsSubscribersDirty,
  selectValidTemplates,
  selectValidSubscribers,
} from '../redux/selectors';
import {
  SEND_NOW,
  formatComponentsFromForm,
  formatOptionsFromComponent,
  getScheduleTime,
  getVariablesLength,
} from '../utils';

export const useBroadcastPage = () => {
  const { broadcastId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    getStatus,
    updateBroadcastAsync,
    updateStatus,
    createStatus,
    createBroadcastAsync,
    whatsAppTemplates,
  } = useBroadcasts(broadcastId);
  const { listStatus } = useSubscribers(broadcastId);
  const broadcast = useSelector(selectBroadcast);
  const deskId = useSelector(selectBroadcastDeskId);
  const isDraft = useSelector(selectIsDraft);
  const slug = useSelector(selectAccountSlug);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  useRtmBroadcast(broadcastId);
  const validSubscribers = useSelector(selectValidSubscribers);
  const templates = useSelector(selectValidTemplates);

  useEffect(() => {
    if (whatsAppTemplates) {
      dispatch(actions.setTemplates(whatsAppTemplates));
    }
  }, [dispatch, whatsAppTemplates]);

  const isSubscribersDirty = useSelector(selectIsSubscribersDirty);

  const { updateSubscribersAsync, updateStatus: updateSubscribersStatus } =
    useSubscribers(broadcastId);

  useEffect(() => {
    const locationState = location.state as LocationState;
    if (!isEmpty(locationState)) {
      dispatch(
        actions.updateBroadcast({
          name: locationState.name,
          integration_id: locationState.integration_id,
          channel: locationState.channel,
        })
      );
      dispatch(actions.updateDeskId(locationState.deskId));
      dispatch(actions.updateisDraft(true));
    }
  }, [dispatch, location.state]);

  const formMethods = useForm<Form>({
    defaultValues: {
      template: null,
      scheduleDate: SEND_NOW,
      scheduleHour: null,
      fileName: '',
      force_new_sessions: true,
      headerMediaParam: {
        type: null,
        value: null,
      },
    },
  });
  const { append: headerAppend } = useFieldArray({
    control: formMethods.control,
    name: 'headerParameters',
  });

  const { append: bodyAppend } = useFieldArray({
    control: formMethods.control,
    name: 'bodyParameters',
  });

  const resetScheduleTime = useCallback(() => {
    if (broadcast?.metadata?.is_send_now) {
      return {
        date: SEND_NOW,
        time: null,
      };
    }
    const formattedDate = broadcast?.scheduled_at
      ? moment.utc(broadcast.scheduled_at).local().format('YYYY-MM-DD h:mm a')
      : null;
    const [date, ...time] = formattedDate ? formattedDate.split(' ') : [];

    return { date: date ?? '', time: time.join(' ') ?? '' };
  }, [broadcast?.metadata?.is_send_now, broadcast?.scheduled_at]);

  useEffect(() => {
    const { date, time } = resetScheduleTime();
    if (deskId && !isDraft) {
      const header = broadcast?.actions?.[0]?.components.find(
        (c) => c.type.toLowerCase() === 'header'
      );
      const mediaParameter =
        header?.format.toLowerCase() !== 'text'
          ? header?.parameters?.[0]
          : null;

      const mediaParameterContent = mediaParameter
        ? (mediaParameter[
            mediaParameter.type
          ] as BroadcastComponentParameterContent)
        : null;

      formMethods.reset({
        template: broadcast.actions?.[0]?.id
          ? {
              value: broadcast.actions?.[0]?.id,
              label: broadcast.actions?.[0]?.name,
              type: broadcast.actions?.[0]?.language,
            }
          : null,
        scheduleDate: date,
        scheduleHour: time,
        headerParameters: [],
        bodyParameters: [],
        fileName: broadcast.metadata?.file_name ?? '',
        force_new_sessions: broadcast?.force_new_sessions ?? true,
        session_timeout: broadcast?.session_timeout ?? null,
        headerMediaParam: mediaParameterContent
          ? {
              type: mediaParameterContent.id ? 'id' : 'link',
              value:
                mediaParameterContent.id ?? mediaParameterContent.link ?? '',
            }
          : null,
      });

      const body = broadcast?.actions?.[0]?.components.find(
        (component) => component.type === 'body'
      );

      for (let i = 0; i < getVariablesLength(header); i++) {
        const option = formatOptionsFromComponent(
          header?.parameters?.[i],
          'header'
        );
        if (option) {
          headerAppend({
            name: option.value,
          });
        }
      }
      for (let i = 0; i < getVariablesLength(body); i++) {
        const option = formatOptionsFromComponent(
          body?.parameters?.[i],
          'body'
        );
        if (option) {
          bodyAppend({
            name: option.value,
          });
        }
      }
    }
  }, [
    bodyAppend,
    broadcast,
    broadcast?.actions,
    broadcast?.channel,
    broadcast?.force_new_sessions,
    broadcast?.metadata?.file_name,
    broadcast?.session_timeout,
    deskId,
    formMethods,
    headerAppend,
    isDraft,
    resetScheduleTime,
  ]);

  const onSubmit = useCallback(
    async (formData: Form | undefined, index: number) => {
      const isDraftSumbit = index === 0;

      if (isDraftSumbit) {
        const isValid = await formMethods.trigger([
          'headerParameters',
          'bodyParameters',
          'headerMediaParam',
        ]);
        if (!isValid) {
          return;
        }
      }

      const data = formData || formMethods.getValues();
      const template = templates?.find((t) => t.id === data.template?.value);

      const components = formatComponentsFromForm(template, data);

      const metadata = {
        ...(broadcast?.metadata ?? {}),
        file_name: data.fileName || undefined,
        is_send_now: data.scheduleDate === SEND_NOW,
      };

      const { name, channel } = broadcast;
      const newBroadcast = {
        name,
        channel,
        integration_id: broadcast.integration_id,
        context: {
          tags: [convertNameToTag(name)],
        },
        metadata,
        scheduled_at: getScheduleTime(data.scheduleDate, data.scheduleHour),
        brain_version: broadcast?.brain_version,
        brain_parent_id: broadcast?.brain_parent_id,
        actions: template
          ? [
              {
                ...template,
                components,
              },
            ]
          : undefined,
        session_timeout: data.session_timeout,
        force_new_sessions: data.force_new_sessions ?? true,
        status: 'draft',
      } as Partial<BroadcastType>;

      let id = broadcast?.broadcast_id;
      if (!isDraft) {
        await updateBroadcastAsync(
          {
            broadcast_id: broadcast.broadcast_id,
            ...newBroadcast,
          },
          {
            onSuccess: (resp) => {
              dispatch(
                addTemporalToast(
                  'success',
                  t('broadcasts.broadcast_updated', { 0: resp.name })
                )
              );
            },
          }
        );
      } else {
        const createdBroadcast = await createBroadcastAsync(newBroadcast);
        id = createdBroadcast.broadcast_id;
      }

      queryClient.invalidateQueries({
        queryKey: [rulesEndpoints.rules(deskId)],
      });

      if (isSubscribersDirty) {
        await updateSubscribersAsync({
          broadcast_id: id,
          subscribers: validSubscribers,
          channel,
        });
      }
      dispatch(actions.revertDirty());
      if (!isDraftSumbit) {
        await updateBroadcastAsync({
          broadcast_id: id,
          status: 'ready',
          scheduled_at: getScheduleTime(data.scheduleDate, data.scheduleHour),
          context: {
            tags: [convertNameToTag(name)],
          },
        });
      }
      setTimeout(() => {
        navigate(`/${slug}/broadcasts`);
      }, 0);
    },
    [
      broadcast,
      createBroadcastAsync,
      deskId,
      dispatch,
      validSubscribers,
      formMethods,
      isDraft,
      isSubscribersDirty,
      navigate,
      queryClient,
      slug,
      t,
      templates,
      updateBroadcastAsync,
      updateSubscribersAsync,
    ]
  );

  const isLoading =
    !isDraft && (getStatus === 'pending' || listStatus === 'pending');

  const saveButtonLoading =
    updateStatus === 'pending' ||
    createStatus === 'pending' ||
    updateSubscribersStatus === 'pending';

  return { formMethods, isLoading, onSubmit, templates, saveButtonLoading };
};
