import { Fragment } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { PlusIcon, Trash2Icon } from 'lucide-react';
import { FieldError, Resolver, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Input from '@/components/atoms/Input/Input';
import MarkdownEditor from '@/components/atoms/MarkdownEditor/MarkdownEditor';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter, scrollToElementById } from '@/util/util';
import { LENGTH_XL } from '@/util/validator';

import { MAX_FORM_CHARACTERS, removeTrailingBRs } from '../../helper';
import { useForceRerender } from '../../hooks/useForceRerender';
import { useTrackFormState } from '../../hooks/useTrackFormState';
import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

type Form = {
  objections: { message: string; response: string }[];
};

const FORM_ID = 'ai-agent-objections';

const NEW_OBJECTION = {
  message: '',
  response: '',
};

const schema = (t: TFunction) =>
  yup
    .object({
      objections: yup.array().of(
        yup.object().shape({
          message: yup
            .string()
            .max(
              LENGTH_XL,
              t('validation.max_length', {
                0: LENGTH_XL,
              })
            )
            .required(t('validation.required')),
          response: yup
            .string()
            .max(
              MAX_FORM_CHARACTERS,
              t('validation.max_length', {
                0: MAX_FORM_CHARACTERS,
              })
            )
            .required(t('validation.required')),
        })
      ),
    })
    .required();

export const Objections = ({ order }: { order: number }) => {
  const brainId = useSelector(selectBrainId);
  const { brain, updateBrain } = useBrains(brainId);
  const { t } = useTranslation();
  const { editorKey, forceRerender } = useForceRerender();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  // RHF
  const {
    register,
    control,
    getValues,
    watch,
    trigger,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<Form>({
    resolver: yupResolver(schema(t)) as Resolver<Form>,
    values: {
      objections:
        brain?.guidelines?.objections?.length > 0
          ? brain.guidelines?.objections
          : [NEW_OBJECTION],
    },
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'objections',
  });

  const isDisabled = !isDirty;

  // Handlers
  const addNewObjection = () => {
    append(NEW_OBJECTION);
  };

  useTrackFormState({
    isDirty: isDirty,
    formId: FORM_ID,
  });

  const submitObjections = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // Identify indices to remove (where both fields are empty)
    const indicesToRemove: number[] = [];

    const objections = watch('objections');

    objections.forEach((group, index) => {
      if (!group.message.trim() && !group.response.trim()) {
        indicesToRemove.push(index);
      }
    });

    // Remove empty groups (in reverse order to avoid index shifting)
    for (let i = indicesToRemove.length - 1; i >= 0; i--) {
      remove(indicesToRemove[i]);
    }

    // If all groups were empty, add one empty group back
    if (indicesToRemove.length === objections.length) {
      append(NEW_OBJECTION);

      // Save empty objections
      updateBrain(
        {
          brain_id: brainId,
          guidelines: {
            ...brain?.guidelines,
            objections: [],
          },
        },
        {
          onSuccess: () => {
            // Force rerender to adjust the editor height
            forceRerender();
          },
        }
      );

      return; // No need to validate further
    }

    // Re-validate the remaining fields
    const isValid = await trigger();

    if (isValid) {
      // Get final objections after the removal of empty ones
      const finalObjections = getValues('objections');
      const cleanedObjections = finalObjections.map((objection) => ({
        ...objection,
        response: removeTrailingBRs(objection.response),
      }));

      updateBrain(
        {
          brain_id: brainId,
          guidelines: {
            ...brain?.guidelines,
            objections: cleanedObjections,
          },
        },
        {
          onSuccess: () => {
            // Force rerender to adjust the editor height
            forceRerender();
          },
        }
      );
    }

    // Scroll to form after pressing save
    scrollToElementById(FORM_ID);
  };

  return (
    <FormCard id={FORM_ID}>
      <FormCard.Header
        title={t('ai_agents.knowledge.objections.title')}
        subtitle={t('ai_agents.knowledge.objections.subtitle')}
        icon={
          <NumberIcon
            color="var(--icon-default-blue)"
            size="large"
            number={order}
          />
        }
      />

      <FormCard.Content>
        {fields.map((field, index) => {
          return (
            <Fragment key={field.id}>
              <Stack my="var(--space-16)" id={field.id}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb="var(--space-4)"
                >
                  <Typography
                    variant="label-caps-large"
                    color="var(--text-default-gray)"
                  >
                    #{index + 1}
                  </Typography>

                  {/* Hide button when there is only 1 objection and both response & message fields are empty */}
                  {!(
                    fields.length === 1 &&
                    !watch(`objections.${index}.message`) &&
                    !watch(`objections.${index}.response`)
                  ) && (
                    <IconButton
                      onClick={() => {
                        if (fields.length === 1) {
                          update(index, NEW_OBJECTION);
                          return;
                        }
                        remove(index);
                      }}
                      ariaLabel={t('common.delete')}
                    >
                      <Trash2Icon size={16} />
                    </IconButton>
                  )}
                </Box>

                <Input
                  size="large"
                  {...register(`objections.${index}.message`)}
                  error={!!errors.objections?.[index]?.message}
                  errorMessage={capitalizeFirstLetter(
                    (errors.objections?.[index]?.message as FieldError)?.message
                  )}
                  disabled={!canWrite}
                  placeholder={t(
                    'ai_agents.knowledge.objections.message_placeholder'
                  )}
                />

                <Box mt="var(--space-8)">
                  <MarkdownEditor
                    defaultValue={field.response}
                    {...register(`objections.${index}.response`)}
                    setValue={setValue}
                    placeholder={t(
                      'ai_agents.knowledge.objections.response_placeholder'
                    )}
                    error={!!errors.objections?.[index]?.response}
                    errorMessage={capitalizeFirstLetter(
                      errors.objections?.[index]?.response?.message
                    )}
                    shouldExpand
                    disabled={!canWrite}
                    key={editorKey}
                    value={watch(`objections.${index}.response`)}
                  />
                </Box>
              </Stack>

              {index === fields.length - 1 && (
                <Box my="var(--space-24)">
                  <Button
                    variant="tertiary"
                    disabled={!canWrite}
                    onClick={addNewObjection}
                    type="button"
                  >
                    <PlusIcon size={16} />
                    {t('ai_agents.knowledge.add_objection')}
                  </Button>
                </Box>
              )}
            </Fragment>
          );
        })}
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          disabled={isDisabled}
          type="button"
          onClick={submitObjections}
          variant="secondary"
          isLoading={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
