import { useCallback } from 'react';

import { ExternalLinkIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Banner } from '@/components/atoms/Banner/Banner';
import Chip from '@/components/atoms/Chip/Chip';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { Brain } from '@/models/brain';
import { Version } from '@/models/version';
import { Rule } from '@/modules/rules/model';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';
import {
  calculateDaysBetweenDates,
  isKeyEnter,
  isKeyTab,
  resolveBrainsPath,
} from '@/util/util';

import styles from './BrainDescription.module.scss';

interface Props {
  brain?: Brain;
  version?: Version;
  rules?: Rule[];
}

const BrainDescription: React.FC<Props> = ({
  brain,
  version,
  rules,
}: Props) => {
  const { t } = useTranslation();
  const createdDaysAgoCount = version?.created
    ? calculateDaysBetweenDates(version.created)
    : null;
  const slug = useSelector(selectAccountSlug);
  const deskId = useSelector(selectDeskId);
  const navigate = useNavigate();
  const { ai_agents } = useFeatureFlag();

  const handleKeyPress = useCallback(
    (e, url) => {
      if (isKeyTab(e)) {
        // Allow the default Tab behavior to continue
        return;
      }
      if (isKeyEnter(e)) {
        navigate(url);
      }
    },
    [navigate]
  );

  return (
    <>
      {!!brain && (
        <div className={styles.brainDescriptionContainer}>
          <div className={styles.titleRow}>
            <div className={styles.brainDescriptionTitleGroup}>
              <h6>{brain.name}</h6>
              {version && (
                <Chip
                  className={styles.chip}
                  size="small"
                  customVariant="neutral"
                  label={
                    version.version === 0
                      ? t('common.draft')
                      : `v${version.version}`
                  }
                />
              )}
            </div>
            <Link
              to={resolveBrainsPath(
                `/${slug}/brains/${brain.brain_id}`,
                ai_agents
              )}
              className={styles.linkExternal}
              tabIndex={0}
              onKeyDown={(e) =>
                handleKeyPress(
                  e,
                  resolveBrainsPath(
                    `/${slug}/brains/${brain.brain_id}`,
                    ai_agents
                  )
                )
              }
            >
              <ExternalLinkIcon size={24} color="var(--icon-default-blue)" />
            </Link>
          </div>

          <div className={styles.brainDescriptionMiddleBox}>
            {!!version && (
              <div className={styles.brainDescription}>
                {version?.description || t('common.no_description_provided')}
              </div>
            )}
            {!!rules?.length && (
              <Banner variant="neutral" relativePosition={true}>
                {t('rules.brain_connected')}
                {rules.map((r) => {
                  return (
                    <div key={r.rule_id}>
                      <Link
                        to={`/${slug}/environments/${deskId}/rules/${r.rule_id}`}
                        className={styles.link}
                        tabIndex={0}
                        onKeyDown={(e) =>
                          handleKeyPress(
                            e,
                            `/${slug}/environments/${deskId}/rules/${r.rule_id}`
                          )
                        }
                      >
                        {r.name}
                      </Link>
                    </div>
                  );
                })}
              </Banner>
            )}
          </div>
          {!!createdDaysAgoCount && (
            <div className={styles.footer}>
              {t('rules.created_ago', { 0: createdDaysAgoCount })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

BrainDescription.displayName = 'BrainDescription';

export default BrainDescription;
