import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import { MessageQuestionIcon } from '@/components/atoms/Icons/MessageQuestionIcon';
import Input from '@/components/atoms/Input/Input';
import useDesks from '@/hooks/useDesks';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { UserMessage } from '@/models/tryIt';
import {
  selectSelectedMessage,
  selectSessionContent,
} from '@/modules/TryIt/redux/selectors';
import { popModal } from '@/redux/modals/actions';
import { selectAccountSlug } from '@/redux/session/selectors';
import { formatLogs } from '@/util/analytics';

import { ContainedCovered } from './ContainedCovered';
import { ContainedCoveredSection } from './ContainedCoveredSection';
import { ConversationIdSection } from './ConversationIdSection';
import { InfoFieldSection } from './InfoFieldsSection';
import { InformationFieldNew } from '../InformationField/InformationFieldNew';

import styles from './SessionSection.module.scss';

type Props = {
  isLoading?: boolean;
};

export const SessionSection = ({ isLoading }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { ai_agents } = useFeatureFlag();
  const slug = useSelector(selectAccountSlug);
  const {
    isContained,
    isCovered,
    sessionId,
    isTest,
    deskId,
    channel,
    tags,
    participatedAgents,
    participatedBrains,
    participatedRules,
    startTime,
    endTime,
    expiredTime,
    totalUserMessages,
  } = useSelector(selectSessionContent);
  const { desk } = useDesks(deskId);

  const { duration, sessionDuration: sessionDuration } = formatLogs({
    start_time: startTime,
    end_time: endTime,
    expired_time: expiredTime,
    channel,
  });

  const selectedNodes = useSelector(selectSelectedMessage);
  const { nodes, index } = selectedNodes;
  const selectedMessage = nodes[index];

  const handleClick = () => {
    dispatch(popModal());
    navigate(`/${slug}/chats/${deskId}/conversations/${sessionId}`);
  };
  const isServiceDesk = !!desk?.is_service_desk;

  const request_id =
    selectedMessage?.request_id ||
    (selectedMessage?.messages?.[0] as UserMessage)?.request_id;
  return (
    <div
      className={cn(styles.container, {
        [styles.hasRequestId]: !!request_id,
      })}
    >
      <ContainedCoveredSection>
        <span>{t('try_it.modal.status')}:</span>
        <ContainedCovered
          truthness={isContained}
          text={
            isContained
              ? t('try_it.modal.contained')
              : t('try_it.modal.not_contained')
          }
          isLoading={isLoading}
          tooltipText={t('try_it.modal.contained_tooltip')}
        />
        <ContainedCovered
          truthness={isCovered}
          text={
            isCovered
              ? t('try_it.modal.covered')
              : t('try_it.modal.not_covered')
          }
          isLoading={isLoading}
          tooltipText={t('try_it.modal.covered_tooltip')}
        />
      </ContainedCoveredSection>

      <ConversationIdSection>
        <div className={styles.idContainer}>
          <Input
            readOnly
            value={sessionId}
            label={t('analytics.fields.session_id')}
            size="small"
          />

          <CopyButton className={styles.copyButton} data={sessionId} />
        </div>
        {isServiceDesk && (
          <Tooltip placement="top" title={t('analytics.navigate_to')} arrow>
            <Button onClick={handleClick}>
              <MessageQuestionIcon
                size={32}
                className="p-1.5"
                color="var(--icon-default-white)"
              />
            </Button>
          </Tooltip>
        )}
      </ConversationIdSection>
      {request_id && (
        <div className={styles.idContainer}>
          <Input
            readOnly
            value={request_id}
            label={t('analytics.fields.request_id')}
            size="small"
          />

          <CopyButton className={styles.copyButton} data={request_id} />
        </div>
      )}

      <InfoFieldSection>
        <InformationFieldNew
          label={t('common.environment')}
          type="desk"
          value={desk}
          isLoading={isLoading}
        />
        <InformationFieldNew
          label={t('common.channel')}
          type="channel"
          value={channel}
          isLoading={isLoading}
          isTest={isTest}
        />
        <InformationFieldNew
          type="duration"
          label={t('common.duration')}
          value={duration == 0 ? null : duration}
          isLoading={isLoading}
        />
        <InformationFieldNew
          type="duration"
          label={t('try_it.modal.expired_after')}
          value={sessionDuration}
          isLoading={isLoading}
        />
        <InformationFieldNew
          type="messages"
          label={t('try_it.modal.messages')}
          value={totalUserMessages > 0 ? String(totalUserMessages) : null}
          isLoading={isLoading}
        />
        <InformationFieldNew
          type="agents"
          label={t('try_it.modal.agents')}
          value={participatedAgents}
          isLoading={isLoading}
        />
        <InformationFieldNew
          type="brains"
          label={t(`try_it.modal.${ai_agents ? 'ai_agents' : 'brains'}`)}
          value={participatedBrains}
          isLoading={isLoading}
          sessionId={sessionId}
        />
        <InformationFieldNew
          type="rules"
          label={t('try_it.modal.rules')}
          value={participatedRules}
          deskId={deskId}
          isLoading={isLoading}
        />
        <InformationFieldNew
          type="tags"
          label={t('common.tags')}
          value={tags}
          isLoading={isLoading}
        />
      </InfoFieldSection>
    </div>
  );
};
