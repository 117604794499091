/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { MutationStatus } from '@tanstack/react-query';
import classNames from 'classnames';
import { ChevronDownIcon, ChevronRightIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import ListSelect, { ListSelectItem } from './ListSelect';
import Button from '../Button/Button/Button';

import styles from './ListSelectRow.module.scss';

export interface ListSelectRowProps {
  item: ListSelectItem;
  child?: boolean;
  selectedRow?: string | number;
  onSelect?: (...args: Array<string | number>) => void;
  onRowClick?: (...args: Array<string | number>) => void;
  secondLevelStatus?: MutationStatus;
  tabIndex?: number;
}

const ListSelectRow: React.FC<ListSelectRowProps> = ({
  item,
  child,
  onSelect,
  selectedRow,
  onRowClick,
  secondLevelStatus,
}: ListSelectRowProps) => {
  const { t } = useTranslation();
  const isRowSelected = selectedRow === item.id;
  const levelAvailable = secondLevelStatus && secondLevelStatus === 'success';
  const loading = secondLevelStatus && secondLevelStatus === 'pending';
  const isOptionOpened = isRowSelected;
  const subListVisible = item.items?.length && isOptionOpened && levelAvailable;

  const onMouseEnter = (id) => {
    if (child) {
      onRowClick(id);
    }
  };

  const handleRowClick = (id) => {
    if (child) {
      onSelect(item.id);
    } else {
      onRowClick(id);
    }
  };

  return (
    <div className={styles.listSelectRow}>
      <div
        className={classNames(
          styles.listSelectRow__header,
          child ? styles.child : ''
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleRowClick(item.id);
        }}
        onMouseEnter={() => onMouseEnter(item.id)}
        tabIndex={0}
        role="listitem"
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            if (child) {
              onSelect(item.id);
            } else {
              handleRowClick(item.id);
            }
          }
        }}
        onFocus={() => {
          if (child) {
            onRowClick(item.id);
          }
        }}
        key={'header-' + item.id}
      >
        {!!item.items?.length && (
          <>
            {isOptionOpened ? (
              <ChevronDownIcon color="var(--icon-default-gray)" size={20} />
            ) : (
              <ChevronRightIcon color="var(--icon-default-gray)" size={20} />
            )}
          </>
        )}
        <span>{item.text}</span>
        <span>
          {item.items?.length ? (
            item.textRight
          ) : (
            <Button
              size="small"
              variant="tertiary"
              className={styles.select}
              onClick={() => onSelect(item.id)}
            >
              {t('common.select')}
            </Button>
          )}
        </span>
      </div>
      {subListVisible && (
        <>
          <div className={styles.separator} />
          <ListSelect
            items={item.items}
            child
            onSelect={(...args) => onSelect(item.id, ...args)}
            onRowClick={(...args) => onRowClick(item.id, ...args)}
          />
        </>
      )}
      {item.items?.length && isOptionOpened && loading && (
        <div
          className={classNames(
            styles.listSelectRow__header,
            child ? styles.child : '',
            styles.loading
          )}
        >
          <span>{t('common.loading')}</span>
        </div>
      )}
    </div>
  );
};

export default ListSelectRow;
